import * as React from "react";
import logo from "../../assets/logo.svg";
import { isLoggedIn } from "../../App";
import { tabMenu } from "../../components/NavBar/NavBar";
import Button from "../../components/Inputs/Button/Button";
import Input from "../../components/Inputs/Text/Input";

export default function Login() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleLogin = () => {
    isLoggedIn.value = true;
    tabMenu.value = "news";
  };

  return (
    <div className="flex h-[calc(100vh-48px)] min-h-[500px]">
      <div className="bg-SPdarkgray w-full p-16 flex flex-col gap-8 justify-center text-left border-SPbordergray border rounded-xl">
        <div className="mb-auto">
          <img src={logo} alt="science protect" />
        </div>
        <div className="text-6xl">
          <span className="font-bold">SCIENCE</span>
          <span>Protect</span>
        </div>
        <div className="text-2xl">
          <span>Un temps d'avance sur la science</span>
        </div>
        <div className="text-2xl text-blue mb-auto">
          <span>
            Anticiper les controverses sur la composition des produits de
            consommation grâce à l'Intelligence Artificielle.
          </span>
        </div>
      </div>

      <div className="w-full p-12 flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-6 w-full max-w-sm">
          <h1 className="text-2xl font-semibold">Login</h1>
          <Input
            id="username"
            label="Username"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            id="password"
            label="Password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="primary" onClick={handleLogin}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
}