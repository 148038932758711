import * as React from "react";
import { isLoggedIn } from "../../App";
import Button from "../../components/Inputs/Button/Button";

export default function Profile() {

    const handleLogout = () => {
      isLoggedIn.value = false;
    };
  return (
    <>
      <div className="profile animated-wrapper widget-wrapper">
        <div className="widget-title">My Profile</div>
        <Button variant="primary" onClick={handleLogout}>
            Logout
          </Button>
      </div>
    </>
  );
}
