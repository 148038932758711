import * as React from "react";
import { signal } from "@preact/signals-react";
import logo from "../../assets/logo.svg";
import profile from "../../assets/profile.png";
import "./NavBar.scss";

export const tabMenu = signal<string>("news");

export default function NavBar() {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    tabMenu.value = event.target.value;
  }

  function handleProfile(target: string) {
    tabMenu.value = target;
  }

  return (
    <div className="header-container">
      <div className="logo">
        <img src={logo} alt="science protect" />
      </div>
      <div className="tabs">
        <input
          type="radio"
          id="radio-1"
          name="tabs"
          value="news"
          onChange={handleChange}
          checked={tabMenu.value === "news"}
        />
        <label className="tab" htmlFor="radio-1">
          What’s new
        </label>
        <input
          type="radio"
          id="radio-2"
          name="tabs"
          value="foods"
          onChange={handleChange}
          checked={tabMenu.value === "foods"}
        />
        <label className="tab" htmlFor="radio-2">
          My foods
        </label>
        <input
          type="radio"
          id="radio-3"
          name="tabs"
          value="issues"
          onChange={handleChange}
          checked={tabMenu.value === "issues"}
        />
        <label className="tab" htmlFor="radio-3">
          My issues
        </label>
        <input
          type="radio"
          id="radio-4"
          name="tabs"
          value="substances"
          onChange={handleChange}
          checked={tabMenu.value === "substances"}
        />
        <label className="tab" htmlFor="radio-4">
          Explore substances
        </label>
        <input
          type="radio"
          id="radio-5"
          name="tabs"
          value="recalls"
          onChange={handleChange}
          checked={tabMenu.value === "recalls"}
        />
        <label className="tab" htmlFor="radio-5">
          Explore recalls
        </label>
        <span
          className={`glider ${tabMenu.value === "profile" ? "hidden" : ""}`}
        ></span>
      </div>
      <div className="profile">
      <img src={profile} alt="science protect" onClick={() => handleProfile('profile')}/>
      </div>
    </div>
  );
}
