import * as React from "react";
import Map from "../../components/Map/Map";
import { global, recalls, substances, opinions } from "./data";
import trend from "../../assets/trendUp.svg";

export default function News() {
  return (
    <>
      <div className="animated-wrapper widget-wrapper">
        <div className="widget-title widget-title-separator">
          Global issues - new publications
        </div>
        <div className="flex w-full justify-between">
          {global.map((card) => (
            <div className="card">
              <div className="card-title">{card.title}</div>
              <div className="card-body">{card.value}</div>
              <div className="chip" style={{background: "#F47C25"}}>
                {card.percentage}%{" "}
                <img
                  src={trend}
                  alt="science protect"
                  className={`${card.trend === "up" ? "" : "trend-down"}`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="animated-wrapper widget-wrapper-two-columns">
        <div className="pl pt pb pr w-100">
          <div className="widget-title widget-title-separator">
            Food recalls trends
          </div>
          <table className="table w-100">
            <tr>
              {recalls.headers.map((header) => (
                <th>{header}</th>
              ))}
            </tr>
            {recalls.values.map((value) => (
              <tr>
                {value.map((v) => (
                  <td>
                    <span className={`${v.type === "chip" ? "chip" : ""}`} style={{background: (v as any).color}}>
                      {v.value ? v.value : '-'}{v.type === 'chip' && '%'}
                    </span>
                  </td>
                ))}
              </tr>
            ))}
          </table>
        </div>
        <div style={{ minWidth: 726 }}>
          <Map />
        </div>
      </div>
      <div className="animated-wrapper widget-wrapper">
        <div className="widget-title widget-title-separator">
        Substances
        </div>
        <table className="table w-100">
            <tr>
              {substances.headers.map((header) => (
                <th>{header}</th>
              ))}
            </tr>
            {substances.values.map((value) => (
              <tr>
                {value.map((v) => (
                  <td>
                    <span className={`${v.type === "chip" ? "chip" : ""}`} style={{background: (v as any).color}}>
                      {v.value ? v.value : '-'}{v.type === 'chip' && '%'}
                    </span>
                  </td>
                ))}
              </tr>
            ))}
          </table>
      </div>
      <div className="animated-wrapper widget-wrapper">
        <div className="widget-title widget-title-separator">
        Last agencies opinions
        </div>
        <table className="table w-100">
            <tr>
              {opinions.headers.map((header) => (
                <th>{header}</th>
              ))}
            </tr>
            {opinions.values.map((value) => (
              <tr>
                {value.map((v) => (
                  <td style={{ width: 300}}>
                    <span className={`${v.type === "chip" ? "chip" : ""}`} style={{background: (v as any).color}}>
                      {v.value ? v.value : '-'}{v.type === 'chip' && '%'}
                    </span>
                  </td>
                ))}
              </tr>
            ))}
          </table>
      </div>
    </>
  );
}
