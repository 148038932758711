import * as React from "react";
import NavBar from "./components/NavBar/NavBar";
import { signal } from "@preact/signals-react";
import News from "./views/News/News";
import Foods from "./views/Foods/Foods";
import Issues from "./views/Issues/Issues";
import Substances from "./views/Substances/Substances";
import Recalls from "./views/Recalls/Recalls";
import Login from "./views/Login/Login";
import { tabMenu } from "./components/NavBar/NavBar";
import Profile from "./views/Profile/Profile";

import "./App.scss";

export const isLoggedIn = signal<boolean>(false);

export default function App() {
  return (
    <div className="flex flex-col items-center p-6 max-w-[1920px] min-w-[1440px] mx-auto">
      {!isLoggedIn.value ? (
        <Login />
      ) : (
        <>
          <NavBar />
          <div className="w-[1218px] mt-[56px] min-h-[calc(100vh-175px)] h-full">
            {tabMenu.value === "news" && <News />}
            {tabMenu.value === "foods" && <Foods />}
            {tabMenu.value === "issues" && <Issues />}
            {tabMenu.value === "substances" && <Substances />}
            {tabMenu.value === "recalls" && <Recalls />}
            {tabMenu.value === "profile" && <Profile />}
          </div>
        </>
      )}
    </div>
  );
}