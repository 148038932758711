import * as React from "react";

export default function Issues() {
  return (
    <>
      <div className="animated-wrapper widget-wrapper">
        <div className="widget-title">Issues</div>
        <div className="card-list">
          <div className="card">5515</div>
          <div className="card">5515</div>
          <div className="card">5515</div>
          <div className="card">5515</div>
          <div className="card">5515</div>
        </div>
      </div>
    </>
  );
}
