import React from "react";

interface InputProps {
  id: string;
  label: string;
  type?: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
}

const Input: React.FC<InputProps> = ({
  id,
  label,
  type = "text",
  placeholder = "",
  value,
  onChange,
  autoComplete = "off",
}) => {
  return (
    <div className="flex flex-col items-start gap-1">
      <label htmlFor={id} className="text-sm text-white">
        {label}
      </label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
        className="px-3 py-2 text-lg w-[350px] text-white placeholder:text-base border border-neutral-700 rounded-xl bg-transparent h-14"
      />
    </div>
  );
};

export default Input;